<template>
  <div>
    <div class="batch_t">
      <div class="branch_l">
        <img
          src="@/assets/structure/close.png"
          class="batch_close"
          @click="close"
        />
        <div>出差</div>
      </div>
      <div class="branch_bto">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="switcOperation">确认</el-button>
      </div>
    </div>

    <div class="batch_c">
      <el-form
        ref="batch"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-form-item label="员工" prop="ids">
          <div class="unableChange">{{ name.join(",") }}</div>
        </el-form-item>
        <el-form-item label="出差地址" prop="address">
          <el-input v-model="formLabelAlign.address"></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="timeBegin">
          <el-cascader
            v-model="formLabelAlign.timeBegin"
            :options="options"
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="结束时间" prop="timeEnd">
          <el-cascader
            v-model="formLabelAlign.timeEnd"
            :options="options"
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <div v-if="isError" style="color: red">{{ error }}</div>
        <el-form-item label="出差时长(天)" prop="ids">
          <div class="unableChange">{{ difference }}</div>
        </el-form-item>
        <el-form-item label="出差事由" prop="description">
          <el-input
            type="textarea"
            :rows="5"
            v-model="formLabelAlign.description"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-form-item label="审批人">
          <div class="unableChange">{{ approvedBy }}</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      formLabelAlign: {}, //提交的表单
      difference: "", //小时差
      handler: {}, //经办人
      submitArr: [], //数组
      options: [], //选择数据
      approvedBy: "", //审批人
      isError: false, //是否可提交
      error: "", //错误提示
      rules: {
        ids: [
          {
            type: "array",
            required: true,
            message: "请选择至少一个用户",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入出差地址", trigger: "blur" },
        ],
        timeBegin: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        timeEnd: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "change",
          },
        ],
        description: [
          { required: true, message: "请输入出差事由", trigger: "blur" },
        ],
      },
      customerList: [],
    };
  },
  props: ["name", "id"],
  created() {
    this.formLabelAlign.ids = this.id;

    var date = this.getAllDatesBetween();
    this.options = date;

    var user = localStorage.getItem("userinfo");
    user = JSON.parse(user);
    this.formLabelAlign.userExec = user.id;
    this.approvedBy = user.name;

    // 获取当前时间
    this.getCurrentDate(2);
  },
  watch: {
    newObj: {
      handler(newValue, oldValue) {
        if (newValue) {
          if (oldValue.description == newValue.description && oldValue.address == newValue.address) {
            if (newValue.timeBegin.length > 1 && newValue.timeEnd.length > 1) {
              this.isWithintheshift();
            }
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    newObj() {
      // 对 obj 进行深拷贝
      return JSON.parse(JSON.stringify(this.formLabelAlign));
    },
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$refs.batch.resetFields();
      this.$emit("close");
    },

    // 确认弹窗
    switcOperation() {
      this.$refs.batch.validate((valid) => {
        if (valid) {
          var value = JSON.stringify(this.formLabelAlign);
          value = JSON.parse(value);
          value.type = 3;
          value.status = 1;
          value.timeExec = this.getCurrentDate(1);

          if (this.isError) {
            return;
          }

          // 判断是上午还是下午
          var string = "";
          string = value.timeBegin[2] + "" + value.timeEnd[2];

          var timeBegin = "";
          var timeEnd = "";
          var start = value.timeBegin[0] + "-" + value.timeBegin[1];
          var end = value.timeBegin[0] + "-" + value.timeBegin[1];
          if (this.submitArr.length > 0) {
            // 如果存在排班
            var firstDate = this.submitArr[0];
            var endDate = this.submitArr[this.submitArr.length - 1];
            // 先判断开头的排班
            if (firstDate.arrange > 0) {
              var attendance = firstDate.bmsAttendanceArrangeGetVO;
              var firstAttendance =
                attendance.arrangeDataList[0].clockData.beginDaySecond;
              var endAttendance =
                attendance.arrangeDataList[
                  attendance.arrangeDataList.length - 1
                ].clockData.endDaySecond;
              if (value.timeBegin[2] == 0) {
                // 如果是上午
                var time = this.convertSecondsToTimeFormat(firstAttendance);
                timeBegin = start + " " + time + ":00";
              } else {
                var time = this.convertSecondsToTimeFormat(
                  (firstAttendance + endAttendance) / 2
                );
                timeBegin = start + " " + time + ":00";
              }
            } else {
              if (value.timeBegin[2] == 0) {
                // 如果是上午
                timeBegin = start + " " + "00:00:00";
              } else {
                // 下午
                timeBegin = start + " " + "12:00:00";
              }
            }

            // 判断结束的排班
            if (endDate.arrange > 0) {
              var attendance = endDate.bmsAttendanceArrangeGetVO;
              var firstAttendance =
                attendance.arrangeDataList[0].clockData.beginDaySecond;
              var endAttendance =
                attendance.arrangeDataList[
                  attendance.arrangeDataList.length - 1
                ].clockData.endDaySecond;
              if (value.timeEnd[2] == 0) {
                // 如果是上午
                var time = this.convertSecondsToTimeFormat(
                  (firstAttendance + endAttendance) / 2
                );
                timeEnd = end + " " + time + ":00";
              } else {
                var time = this.convertSecondsToTimeFormat(endAttendance);
                timeEnd = end + " " + time + ":00";
              }
            } else {
              if (value.timeEnd[2] == 0) {
                // 如果是上午
                timeEnd = end + " " + "12:00:00";
              } else {
                // 下午
                timeEnd = end + " " + "23:59:59";
              }
            }
          } else {
            // 不存在排班
            if (value.timeBegin[2] == 0) {
              timeBegin = start + " " + "00:00:00";
            } else {
              timeBegin = start + " " + "12:00:00";
            }

            if (value.timeEnd[2] == 0) {
              timeEnd = end + " " + "12:00:00";
            } else {
              timeEnd = end + " " + "23:59:59";
            }
          }

          // 判断是上午下午组成新的字符串
          var beginString = "";
          var endString = "";
          if (value.timeBegin[2] == 0) {
            beginString = start + " " + "上午";
          } else {
            beginString = start + " " + "下午";
          }
          if (value.timeEnd[21] == 0) {
            endString = end + " " + "上午";
          } else {
            endString = end + " " + "下午";
          }

          value.timeBegin = this.adjustTimeString(timeBegin);
          value.timeEnd = this.adjustTimeString(timeEnd);

          value.description =
            value.description +
            "(" +
            beginString +
            "," +
            endString +
            ")" +
            this.difference;

          http.post("/bmsApply/add", value).then((res) => {
            if (res.code == 200) {
              this.$message.success("登记成功");
              this.close();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 判断结束时间是否是次日
    adjustTimeString(timeStr) {
      // 分割时间字符串为日期和时间部分
      const parts = timeStr.split(" ");
      const datePart = parts[0];
      const timePart = parts[1];

      // 分割时间部分为小时、分钟和秒
      const [hoursStr, minutesStr, secondsStr] = timePart
        .split(":")
        .map(Number);

      // 检查小时数是否合法（0-23）
      if (hoursStr >= 24) {
        // 获取日期部分（年-月-日）
        const [year, month, day] = datePart.split("-").map(Number);

        // 计算新的日期（增加一天）
        const newDay = day + 1;
        const newMonth = month; // 注意：这里可能需要处理月份增加的逻辑（例如从12月到1月）
        const newYear = year; // 同样，这里可能需要处理闰年和年份增加的逻辑

        // 计算新的小时数（如果小时数大于23，则减去24）
        const newHours = hoursStr % 24;

        // 格式化新的时间字符串并返回
        return `${newYear}-${newMonth.toString().padStart(2, "0")}-${newDay
          .toString()
          .padStart(2, "0")} ${newHours
          .toString()
          .padStart(2, "0")}:${minutesStr
          .toString()
          .padStart(2, "0")}:${secondsStr.toString().padStart(2, "0")}`;
      }

      // 如果时间部分存在且小时数合法，或者时间部分不存在，直接返回原始字符串
      return timeStr;
    },
    // 获取当前时间
    getCurrentDate(type) {
      var now = new Date();

      // 获取年份
      var year = now.getFullYear();

      // 获取月份（注意：月份是从0开始的，所以我们需要加1）
      var month = now.getMonth() + 1;
      month = month < 10 ? "0" + month : month; // 如果月份是一位数，前面补零

      // 获取日期
      var date = now.getDate();
      date = date < 10 ? "0" + date : date; // 如果日期是一位数，前面补零

      // 获取小时
      var hours = now.getHours();
      hours = hours < 10 ? "0" + hours : hours; // 如果小时是一位数，前面补零

      // 获取分钟
      var minutes = now.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes; // 如果分钟是一位数，前面补零
      // 秒
      var seconds = now.getSeconds();
      seconds = seconds < 10 ? "0" + seconds : seconds;

      // 将获取到的年月日时分拼接成字符串
      var currentDateTime =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;

      var currentDate = year + "-" + month + "-" + date;

      if (type == 1) {
        return currentDateTime;
      } else {
        this.$set(this.formLabelAlign, "timeBegin", [currentDate]);
        this.$set(this.formLabelAlign, "timeEnd", [currentDate]);
      }
    },
    // 获取近两年每个月份
    getAllDatesBetween() {
      var yearArr = [];
      var arr = [];
      var date = new Date();
      for (let index = 2024; index <= date.getFullYear(); index++) {
        yearArr.push(index);
      }
      // 遍历年份数组
      yearArr.forEach((year) => {
        var yearObj = {
          value: year,
          label: year + "年",
          children: [],
        };

        // 遍历月份（1-12）
        for (var month = 1; month <= 12; month++) {
          var monthStr = month < 10 ? "0" + month : month.toString();

          // 假设每个月都按31天来处理，实际应用中应考虑每月的实际天数
          for (var day = 1; day <= 31; day++) {
            var dayStr = day < 10 ? "0" + day : day.toString();
            var dateStr = monthStr + "月" + dayStr + "日";

            var dateObj = {
              value: monthStr + "-" + dayStr,
              label: dateStr,
              children: [
                { value: 0, label: "上午" },
                { value: 1, label: "下午" },
              ],
            };

            yearObj.children.push(dateObj);
          }
        }

        // 将生成的年份对象添加到结果数组中
        arr.push(yearObj);
      });

      return arr;
    },
    // 判断是否在班次内
    isWithintheshift() {
      var start =
        this.formLabelAlign.timeBegin[0] +
        "-" +
        this.formLabelAlign.timeBegin[1];
      var end =
        this.formLabelAlign.timeEnd[0] + "-" + this.formLabelAlign.timeEnd[1];
      var a = new Date(start);
      var b = new Date(end);
      if (a && b) {
        if (a > b) {
          this.error = "结束时间必须大于开始时间";
          this.isError = true;
          return;
        }

        var day = this.daysBetweenDates(start, end);
        if (this.formLabelAlign.timeBegin[2] == 1) {
          day = day - 0.5;
        }
        if (this.formLabelAlign.timeEnd[2] == 0) {
          day = day - 0.5;
        }

        this.difference = day;
        http
          .get("/bmsAttendanceRecord/search", {
            user: this.id[0],
            beginDay: start,
            endDay: end,
            pageNum: 1,
            pageSize: 10,
          })
          .then((res) => {
            if (res.code == 200) {
              var arr = res.data.list;
              if (arr.length > 0) {
                this.submitArr = arr;
                this.isError = false;
              } else {
                this.daysBetweenDates(start, end);
                this.isError = false;
              }
            }
          });
      }
    },
    // 标准时间转换成yyyy-mm-dd
    formatDate(standardTime) {
      const date = new Date(standardTime);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 秒转成时间
    convertSecondsToTimeFormat(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      const hoursStr = hours.toString().padStart(2, "0");
      const minutesStr = minutes.toString().padStart(2, "0");

      return `${hoursStr}:${minutesStr}`;
    },
    // 处理日期
    convertDateStringToISOFormat(dateString) {
      // 提取年份
      let yearMatch = dateString.match(/\d{4}年?/);
      let year = yearMatch
        ? yearMatch[0].replace("年", "")
        : new Date().getFullYear();

      // 提取月份和日期
      let monthDayMatch = dateString.match(/(\d+月)(\d+日)/);
      if (!monthDayMatch) {
        // 如果匹配失败，说明格式不正确，返回原始字符串
        return dateString;
      }

      let month = monthDayMatch[1].replace("月", "");
      let day = monthDayMatch[2].replace("日", "");

      // 如果月份或日期只有一位数，前面补0
      month = month.padStart(2, "0");
      day = day.padStart(2, "0");

      // 拼接成YYYY-MM-DD格式
      let isoDateString = `${year}-${month}-${day}`;
      return isoDateString;
    },
    // 判断两个时间相隔几天
    daysBetweenDates(dateString1, dateString2) {
      const date1 = new Date(dateString1 + "T00:00:00"); // 开始时间设置为当天的0点
      const date2 = new Date(dateString2 + "T23:59:59"); // 结束时间设置为当天的23:59:59
      const diffInMs = date2 - date1;
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)); // 转换为天数并向上取整
      return diffInDays;
    },
    // 相隔小时差
    calculateHourDifference(dateStr1, dateStr2) {
      const date1 = new Date(dateStr1);
      const date2 = new Date(dateStr2);

      // 计算时间差，单位为毫秒
      const timeDiff = Math.abs(date2 - date1);

      // 将毫秒转换为小时
      const hourDiff = (timeDiff / (1000 * 60 * 60)).toFixed(1);

      return hourDiff + "h";
    },
  },
};
</script>

<style scoped>
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
  overflow-y: scroll;
}
.batch_c::-webkit-scrollbar {
  display: none;
}
.department_search_inp {
  width: 100%;
  border: 1px solid #dcdfe6;
}
::v-deep .depa .el-select-dropdown {
  top: 330px !important;
}
::v-deep .batch_c .el-autocomplete {
  width: 100%;
}
.unableChange {
  width: 413px;
  height: 40px;
  border: 1px solid #d0d0d0;
  padding-left: 17px;
  box-sizing: border-box;
  line-height: 40px;
  font-size: 16px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
